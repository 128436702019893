import React, { useState, useEffect } from "react";
import axios from "axios";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import { Box, CircularProgress, InputBase, Modal, Paper, Skeleton } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useSnackbar } from "notistack";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

//Snackbar
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

//Icons
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

import moment from "moment";

//Upload
import { getAuth, signInAnonymously } from "firebase/auth";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../../../firebaseConfig";
import { useAuth } from "../../../../auth";
import ClinicForm from "./ClinicForm";
import { DeleteOutlined, ImageOutlined } from "@mui/icons-material";
import { isAusDomain } from "../../clinicalNotesClinicalRoute/lib/utils";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const languageList = [
  { value: "English", label: "English" },
  { value: "Hindi", label: "Hindi" },
  { value: "Bengali", label: "Bengali" },
  { value: "Japanese", label: "Japanese" },
  { value: "Korean", label: "Korean" },
];


const EditClinicalProfile = ({
  clinicData = {},
  professionalData = {},
  fetchData,
  data: {
    address = "",
    city = "",
    country_id = "",
    country_phone_code = "",
    dob = "",
    email = "",
    email_verified = false,
    first_name = "",
    middle_name = "",
    last_name = "",
    gender_id = "",
    id_proof_link = "",
    id_proof_type_id = "",
    image_link = "",
    landmark = "",
    mobile = "",
    state_id = "",
    timezone = "",
    zip = "",
    qualification = "",
    prescriber_number = "",
    ahpra_number = "",
    register_as_id = 0,
    clinic_id = 0
  } = {},
}) => {
  // Consolidated profile state for personal & address details
  const [profile, setProfile] = useState({
    firstName: first_name,
    middleName: middle_name,
    lastName: last_name,
    address: address,
    mobile: mobile,
    dob: dob,
    gender: gender_id,
    city: city,
    state: state_id,
    zip: zip,
    landmark: landmark,
    qualification: qualification,
    prescriber_number: prescriber_number,
    ahpra_number: ahpra_number,
    register_as_id: register_as_id,
    clinic_id: clinic_id,
  });
  const [profilePicture, setProfilePicture] = useState(image_link);
  const [country, setCountry] = useState(country_id);
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [countryCallingCode, setCountryCallingCode] = useState("");
  const [genderList, setGenderList] = useState([]);
  const [proofIDList, setProofIDList] = useState([]);
  const [proofName, setProofName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [uploading, setUploading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const { currentUser } = useAuth();
  const userID = JSON.parse(localStorage.getItem("profile"))?.UserId;
  const defaultImage = "/assets/img/default-profile.jpg";

  const headers = {
    "X-API-Key": process.env.REACT_APP_EXPERT_API_KEY,
    "Content-Type": "application/json",
  };

  // Helper function for API calls
  const fetchAPI = async (url) => {
    try {
      const response = await fetch(url, { method: "GET", headers });
      if (!response.ok) throw new Error("Network response was not ok");
      return await response.json();
    } catch (error) {
      console.error(error);
    }
  };
  const [clinicDetails, setClinicDetails] = useState({
    name: "",
    address_line_1: "",
    address_line_2: "",
    city: "",
    state_id: "",
    postcode: "",
    country_id: 13,
    image_url: "",
  });
  const [yourClinic, setYourClinic] = useState({
    name: "",
    address_line_1: "",
    address_line_2: "",
    city: "",
    state_id: "",
    postcode: "",
    country_id: 13,
    image_url: "",
  });
  const [clinicstateList, setClinicStateList] = useState([]);
  const [registerAsList, setRegisterAsList] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [clinicUploading, setClinicUploading] = useState(false);
  const [yourClinicState, setYourClinicState] = useState([]);
  // Update consolidated state when props change
  useEffect(() => {
    setProfile({
      firstName: first_name,
      middleName: middle_name,
      lastName: last_name,
      address: address,
      mobile: mobile,
      dob: dob,
      gender: gender_id,
      city: city,
      state: state_id,
      zip: zip,
      landmark: landmark,
      qualification: professionalData?.qualification,
      prescriber_number: professionalData?.prescriber_number,
      ahpra_number: professionalData?.ahpra_number,
      register_as_id: professionalData?.register_as_id,
      clinic_id: clinic_id,
    });
    if (clinicData) {
      // setClinicDetails(clinicData)
      setYourClinic(clinicData)
    }
    setProfilePicture(image_link);
    setCountry(country_id);
    setIsLoading(false);
  }, [first_name, middle_name, last_name, address, professionalData?.register_as_id, clinicData, mobile, dob, gender_id, city, state_id, zip, landmark, qualification, prescriber_number, ahpra_number, register_as_id, clinic_id, image_link, country_id]);



  const [open, setOpen] = useState(false);
  // Fetch countries only once on mount
  useEffect(() => {
    fetchAPI("https://us.uwc.world/new-uwc/country?all=true").then((data) => {
      if (data?.data?.countries) {
        setCountryList(data.data.countries);
      }
    });

  }, []); // Only fetch once when component mounts

  // Fetch states when countryList changes or country_id is selected
  useEffect(() => {
    if (!countryList.length || !country) return;
    const countryItem = countryList.find((item) => item.id === country);
    const countryCode = countryItem?.code;
    if (!countryCode) return;

    fetchAPI(`https://us.uwc.world/new-uwc/state/${countryCode}`).then((data) => {
      if (data?.data?.states) {
        setStateList(data.data.states);
      }
    });
    fetchAPI(`https://us.uwc.world/new-uwc/registration/registeras?country_code=${countryCode}`).then((data) => {
      if (data?.data?.register_as) {
        setRegisterAsList(data.data.register_as);
      }
    });


  }, [countryList, country]); // Re-fetch when countryList or country changes

  // Fetch genders only once when currentUser changes
  useEffect(() => {
    if (!currentUser) return;
    fetchAPI(`https://us.uwc.world/new-uwc/gender`).then((data) => {
      if (data?.code === 200) {
        setGenderList(data.data.genders);
      }
    });
  }, []);

  // Fetch country calling code when country changes
  useEffect(() => {
    if (!country || !countryList.length) return;
    const countryItem = countryList.find((item) => item.id === country);
    const countryCode = countryItem?.code;
    if (countryCode) {
      fetchAPI(`https://us.uwc.world/new-uwc/country/call-code/${countryCode}`).then(
        (data) => {
          setCountryCallingCode(data?.data?.phone_code);
        }
      );
    }
  }, [country, countryList]);

  // Fetch ID Proofs when currentUser changes
  useEffect(() => {
    if (!currentUser) return;
    fetchAPI(`https://us.uwc.world/new-uwc/registration/id-proofs`).then((data) => {
      setProofIDList(data?.data?.id_proofs || []);
    });
  }, []);

  // Set Proof Name based on ID proof type when proofIDList or id_proof_type_id changes
  useEffect(() => {
    if (!id_proof_type_id || !proofIDList.length) return;
    const proofItem = proofIDList.find((item) => item.id === id_proof_type_id);
    setProofName(proofItem?.name);
  }, [proofIDList, id_proof_type_id]);

  // Firebase anonymous login
  useEffect(() => {
    const auth = getAuth();
    signInAnonymously(auth)
      .then(() => {
        console.log("SIGNED IN");
      })
      .catch((error) => {
        console.error("Firebase sign-in error:", error);
      });
  }, []);



  const handleChangePic = async (event, type) => {
    try {
      const file = event.target.files[0];
      event.target.value = null; // Reset file input to allow re-upload of same file
      const allowedTypes = ["image/png", "image/jpg", "image/jpeg"];
      if (allowedTypes.includes(file?.type)) {
        const isClinicPic = type === 'clinic';
        const setUploadingFunc = isClinicPic ? setClinicUploading : setUploading;
        const setDetailsFunc = isClinicPic ? setClinicDetails : setProfilePicture;

        setUploadingFunc(true);
        const dirName = moment().format("MMMM_YYYY");
        const storageRef = ref(storage, `${dirName}/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // Optionally, update progress here
          },
          (error) => {
            console.error("Upload error:", error);
          },
          async () => {
            try {
              const url = await getDownloadURL(uploadTask.snapshot.ref);
              if (isClinicPic) {
                setDetailsFunc((prev) => {
                  return (
                    {
                      ...prev,
                      image_url: url,
                    }
                  )
                }
                );
              } else {
                setDetailsFunc(url);
              }
              setUploadingFunc(false);
            } catch (error) {
              console.error("Error getting download URL:", error);
            }
          }
        );
      } else {
        console.error("Incorrect file format.");
        enqueueSnackbar("File type not accepted. Try PNG, JPEG, or JPG files.", { variant: "error" });
      }
    } catch (error) {
      console.error("Error handling file upload:", error);
    }
  };


  const handleDeleteProfilePic = () => {
    setProfilePicture(defaultImage);
  };

  // Generic change handler for text fields
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfile((prev) => ({ ...prev, [name]: value }));
  };

  // Update Profile API call
  const handleUpdateProfile = async () => {
    if (profile.mobile && !/^\d{9,10}$/.test(profile.mobile)) {
      enqueueSnackbar("Please enter valid mobile number", { variant: "error" });
      return;
    }
    const payload = {
      uwc_user_id: userID,
      email: email,
      mobile: profile.mobile,
      image_link: profilePicture,
      address: profile.address,
      state_id: profile.state,
      city: profile.city,
      zip: profile.zip,
      country_phone_code,
      dob: profile.dob,
      landmark: profile.landmark,
      gender_id: profile.gender,
      last_name: profile.lastName,
      first_name: profile.firstName,
      qualification: profile.qualification,
      prescriber_number: profile.prescriber_number,
      ahpra_number: profile.ahpra_number,
      register_as_id: profile.register_as_id,
      clinic_id: profile.clinic_id,
    };

    try {
      const response = await fetch(
        `https://us.uwc.world/new-uwc/expert/dashboard/update-profile-detail`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(payload),
        }
      );
      if (!response.ok) throw new Error("Network response was not ok");
      const postResponse = await response.json();
      if (postResponse.code === 200) {
        const localData = JSON.parse(localStorage.getItem("profile"));
        const newLocalData = { ...localData, ProfileImage: profilePicture };
        localStorage.setItem("profile", JSON.stringify(newLocalData));
        enqueueSnackbar("Profile updated successfully", { variant: "success" });
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };


  // Update Profile API call
  const handleUpdateClinicalProfile = async (clinic_id) => {
    if (profile.mobile && !/^\d{10}$/.test(profile.mobile)) {
      enqueueSnackbar("Please enter valid mobile number", { variant: "error" });
      return;
    }
    const payload = {
      uwc_user_id: userID,
      email: email,
      mobile: profile.mobile,
      image_link: profilePicture,
      address: profile.address,
      state_id: profile.state,
      city: profile.city,
      zip: profile.zip,
      country_phone_code,
      dob: profile.dob,
      landmark: profile.landmark,
      gender_id: profile.gender,
      last_name: profile.lastName,
      first_name: profile.firstName,
      qualification: profile.qualification,
      prescriber_number: profile.prescriber_number,
      ahpra_number: profile.ahpra_number,
      register_as_id: profile.register_as_id,
      clinic_id: clinic_id,
    };

    try {
      const response = await fetch(
        `https://us.uwc.world/new-uwc/expert/dashboard/update-profile-detail`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(payload),
        }
      );
      if (!response.ok) throw new Error("Network response was not ok");
      const postResponse = await response.json();
      if (postResponse.code === 200) {
        const localData = JSON.parse(localStorage.getItem("profile"));
        const newLocalData = { ...localData, ProfileImage: profilePicture };
        localStorage.setItem("profile", JSON.stringify(newLocalData));
        enqueueSnackbar("Profile updated successfully", { variant: "success" });
        setTimeout(() => {
          fetchData();
        }, 1000);

      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };


  // Fetch states when country is selected
  useEffect(() => {
    const fetchStates = async () => {
      try {
        if (!clinicDetails.country_id) return;

        const countryItem = countryList.find(
          (item) => item.id === clinicDetails.country_id
        );

        if (!countryItem) return;

        const countryCode = countryItem?.code;
        if (!countryCode) return;

        const data = await fetchAPI(
          `https://us.uwc.world/new-uwc/state/${countryCode}`
        );

        if (data?.data?.states) {
          setClinicStateList(data.data.states);
        } else {
          setClinicStateList([]);
        }
      } catch (error) {
        console.error("Error fetching states:", error);
      }
    };

    fetchStates();
  }, [clinicDetails.country_id, , countryList]);
  useEffect(() => {
    const fetchStates = async () => {
      try {
        if (!yourClinic.country_id) return;

        const countryItem = countryList.find(
          (item) => item.id === yourClinic.country_id
        );

        if (!countryItem) return;

        const countryCode = countryItem?.code;
        if (!countryCode) return;

        const data = await fetchAPI(
          `https://us.uwc.world/new-uwc/state/${countryCode}`
        );

        if (data?.data?.states) {
          setYourClinicState(data.data.states);
        } else {
          setYourClinicState([]);
        }
      } catch (error) {
        console.error("Error fetching states:", error);
      }
    };

    fetchStates();
  }, [yourClinic.country_id, , countryList]);

  // Handle form input changes
  const handleClinicInputChange = (e) => {
    const { name, value } = e.target;
    setClinicDetails({
      ...clinicDetails,
      [name]: value,
    });
  };

  // Submit form

  const handleSubmit = async () => {
    setIsSubmitting(true);

    // Validate mandatory fields
    const {
      name,
      address_line_1,

      city,
      state_id,
      postcode,
      country_id,
    } = clinicDetails;

    if (!name || name.trim() === "") {
      enqueueSnackbar("Please fill mandatory fields.", { variant: "error" });
      setIsSubmitting(false);
      return;
    }

    try {
      const response = await fetch(
        "https://us.uwc.world/new-uwc/uwc_expert/copilot/clinic/create",
        {
          method: "POST",
          headers,
          body: JSON.stringify(clinicDetails),
        }
      );

      if (response.ok) {
        const data = await response.json();
        if (data.ResponseMessage === "success") {
          const clinicId = data.data.clinic_id;

          handleUpdateClinicalProfile(clinicId);

        }
        enqueueSnackbar("Clinic added successfully.", { variant: "success" });
        setClinicDetails({
          name: "",
          address_line_1: "",
          address_line_2: "",
          city: "",
          state_id: "",
          postcode: "",
          country_id: 13,
          image_url: "",
        })
        setOpen(false);
      } else {
        enqueueSnackbar("Error creating clinic. Please try again.", { variant: "error" });
      }
    } catch (error) {
      alert("Error creating clinic. Please try again.");
      console.error("Submit error:", error);
    }

    setIsSubmitting(false);
  };




  const handleDeleteClinicPic = () => {
    setClinicDetails({
      ...clinicDetails,
      image_url: "",
    });
  };



  // Field configurations to avoid repetitive JSX in the UI
  const personalFields = isAusDomain() ? [
    { name: "firstName", label: "First Name", xs: 12, sm: 4 },
    { name: "lastName", label: "Last Name", xs: 12, sm: 4 },
    { name: "qualification", label: "Qualifications", xs: 12, sm: 4 },
    { name: "prescriber_number", label: "Prescriber Number", xs: 12, sm: 4 },
    { name: "ahpra_number", label: "AHPRA Number", xs: 12, sm: 4 },

  ] : [
    { name: "firstName", label: "First Name", xs: 12, sm: 4 },
    { name: "lastName", label: "Last Name", xs: 12, sm: 4 },
    { name: "qualification", label: "Qualifications", xs: 12, sm: 4 },
  ];

  const addressFields = [
    { name: "address", label: "Address", xs: 12, sm: 4 },
    { name: "landmark", label: "Landmark (Optional)", xs: 12, sm: 4 },
  ];

  const additionalAddressFields = [
    { name: "city", label: "City", xs: 12, sm: 4 },
    { name: "zip", label: "Post Code/Zip Code", xs: 12, sm: 4 },
  ];

  const [searchedData, setSearchedData] = useState([]);
  useEffect(() => {


    // Debounce timer
    const debounceTimer = setTimeout(() => {
      if (inputValue?.trim() === "") {
        setSearchedData([]);
        return;
      }

      const fetchClinicData = async () => {

        try {
          const response = await fetch(
            `https://us.uwc.world//new-uwc/uwc_expert/copilot/clinic/list/${inputValue}`,
            {
              method: "GET",
              headers
            }
          );
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const jsonData = await response.json();
          // console.log("jsonData", jsonData);
          setSearchedData(jsonData?.data?.clinics);
        } catch (error) {
          console.log("Error:", error);

        }
      };

      fetchClinicData();
    }, 500); // 500 ms debounce time

    return () => {
      clearTimeout(debounceTimer);
    };
  }, [inputValue]);


  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  return (
    <>
      {/* Profile Picture Section */}
      <div className="Content--Section">
        <div className="mt-4 d-flex align-items-center gap-3">
          <div className="position-relative">
            {isLoading || !profilePicture ? (
              <Skeleton
                sx={{ width: "120px", height: "120px", borderRadius: "20px" }}
                variant="rectangular"
              />
            ) : (
              <img
                src={profilePicture}
                width={120}
                height={120}
                alt=""
                loading="lazy"
                style={{
                  borderRadius: "20px",
                  objectFit: "cover",
                  backgroundColor: "rgba(0,0,0,0.07)",
                }}
              />
            )}
            {uploading && (
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <CircularProgress />
              </div>
            )}
          </div>
          <div>
            <Button
              component="label"
              htmlFor="profile-pic-upload"
              id="change_profile_pic"
              className="change_profile_pic"
              sx={{
                backgroundColor: "#F5F5F5",
                color: "#414141",
                borderRadius: "5px",
                padding: "10px 20px",
                textTransform: "capitalize",
                fontWeight: "600",
                display: "flex",
                alignItems: "center",
                gap: "10px",
                width: "200px",
              }}
            >
              <input
                id="profile-pic-upload"
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleChangePic}
              />
              <ImageOutlinedIcon />
              Change Profile Pic
            </Button>
            <Button
              onClick={handleDeleteProfilePic}
              sx={{
                backgroundColor: "#D2293D1A",
                color: "#D2293D",
                borderRadius: "5px",
                padding: "10px 20px",
                textTransform: "capitalize",
                fontWeight: "600",
                display: "flex",
                alignItems: "center",
                gap: "10px",
                width: "200px",
                marginTop: "20px",
              }}
              id="delete_profile_click"
              className="delete_profile_click"
            >
              <DeleteOutlineOutlinedIcon />
              Delete Profile Pic
            </Button>
          </div>
        </div>
      </div>

      {/* Personal Details Section */}
      <div className="Content--Section">
        <h6>Personal Details</h6>
        <Grid container spacing={2} sx={{ marginTop: "20px" }}>
          {personalFields.map((field) => (
            <Grid item xs={field.xs} sm={field.sm} key={field.name}>
              <TextField
                name={field.name}
                label={field.label}
                variant="outlined"
                fullWidth
                value={profile[field.name] || ""}
                onChange={handleInputChange}
              />
            </Grid>
          ))}
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel id="gender-select-label">Gender</InputLabel>
              <Select
                fullWidth
                labelId="gender-select-label"
                id="gender-select"
                value={profile.gender}
                label="Gender"
                onChange={(e) =>
                  setProfile((prev) => ({ ...prev, gender: e.target.value }))
                }
              >
                {genderList.map((g) => (
                  <MenuItem value={g.id} key={g.id}>
                    {g.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              id="email"
              label=""
              value={email}
              variant="outlined"
              fullWidth
              disabled
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {email_verified && (
                      <DoneOutlinedIcon sx={{ color: "#55A51D" }} />
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel id="register-as-select-label">Practitioner Type</InputLabel>
              <Select
                fullWidth
                labelId="register-as-select-label"
                id="register-as-select"
                value={profile.register_as_id || ""}
                label="Practitioner Type"
                onChange={(e) =>
                  setProfile((prev) => ({ ...prev, register_as_id: e.target.value }))
                }
              >
                {registerAsList.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={profile.dob ? dayjs(profile.dob) : null}
                label="Date of Birth"
                sx={{ width: "100%", paddingTop: "0" }}
                onChange={(newValue) =>
                  setProfile((prev) => ({
                    ...prev,
                    dob: newValue ? dayjs(newValue).format("YYYY-MM-DD") : null,
                  }))
                }
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      </div>

      {/* Address Details Section */}
      <div className="Content--Section">
        <h6>Address Details</h6>
        <Grid container spacing={2} sx={{ marginTop: "20px" }}>
          {addressFields.map((field) => (
            <Grid item xs={field.xs} sm={field.sm} key={field.name}>
              <TextField
                name={field.name}
                label={field.label}
                variant="outlined"
                fullWidth
                value={profile[field.name] || ""}
                onChange={handleInputChange}
              />
            </Grid>
          ))}
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel id="country-select-label">Country</InputLabel>
              <Select
                fullWidth
                labelId="country-select-label"
                id="country-select"
                value={country_id || ""}
                label="Country"
                disabled
              >
                {countryList.map((ctry) => (
                  <MenuItem key={ctry.code} value={ctry.id}>
                    {ctry.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel id="state-select-label">State</InputLabel>
              <Select
                fullWidth
                labelId="state-select-label"
                id="state-select"
                value={profile.state || ""}
                label="State"
                onChange={(e) =>
                  setProfile((prev) => ({ ...prev, state: e.target.value }))
                }
              >
                {stateList.map((item) => (
                  <MenuItem key={item.code} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {additionalAddressFields.map((field) => (
            <Grid item xs={field.xs} sm={field.sm} key={field.name}>
              <TextField
                name={field.name}
                label={field.label}
                variant="outlined"
                fullWidth
                value={profile[field.name] || ""}
                onChange={handleInputChange}
              />
            </Grid>
          ))}
          <Grid item xs={12} sm={4}>
            <TextField
              id="timezone"
              label="Timezone"
              value={timezone}
              variant="outlined"
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              name="mobile"
              id="mobile"
              label="Mobile (Optional)"
              variant="outlined"
              value={profile.mobile || ""}
              onChange={handleInputChange}
              InputProps={{
                inputMode: "numeric",
                startAdornment: (
                  <InputAdornment position="start">
                    {country_phone_code || "+91"}
                  </InputAdornment>
                ),
              }}
              fullWidth
            />
          </Grid>
        </Grid>
        <Button
          variant="contained"
          disableElevation
          onClick={handleUpdateProfile}
          className="PrimaryCTA update_profile"
          id="update_profile"
          sx={{ marginLeft: "auto", display: "block", marginTop: "30px" }}
        >
          Update Profile
        </Button>
      </div>

      {isAusDomain() && <Box>
          {/* Title */}
          <h6>{"Add Clinic"}</h6>

          {/* Search & Join Clinics Section */}
          <div className="d-flex gap-4 align-items-center">
            <Paper
              component="form"
              sx={{
                p: "4px",
                display: "flex",
                alignItems: "center",
                maxWidth: "500px",
                width: "100%",
                borderRadius: "8px",
                margin: "10px 0",
              }}
              className="shadow-none border border-2"
            >
              <InputBase
                id="search_clients"
                sx={{ ml: 1, flex: 1 }}
                placeholder={`Search Clinics by Name`}
                value={inputValue}
                onChange={handleChange}
              />
            </Paper>
            <span>Or</span>
            <Button onClick={() => setOpen(true)} className="PrimaryCTA" >
              Add Details Manually
            </Button>
          </div>

          {/* Display Searched Clinics (No Changes Here) */}
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill, minmax(320px, 1fr))",
              gap: "20px",
              padding: "20px",
            }}
            className="mt-3"
          >
            {searchedData?.length > 0 &&
              searchedData?.map((item) => (
                <div
                  key={item?.id}
                  className="d-flex gap-3 align-items-center"
                  style={{
                    padding: "12px",
                    borderRadius: "12px",
                    background: "#fff",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                    transition: "transform 0.2s ease-in-out",
                  }}
                  onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.03)")}
                  onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
                >
                  <img
                    src={item?.image_url}
                    width={100}
                    height={100}
                    alt={item?.name || "Profile"}
                    loading="lazy"
                    style={{
                      borderRadius: "12px",
                      objectFit: "cover",
                      backgroundColor: "rgba(0, 0, 0, 0.07)",
                      flexShrink: 0,
                    }}
                  />
                  <div style={{ height: "100px" }} className="d-flex flex-column gap-2 h-full w-full justify-content-between">
                    <div className="w-full">
                      <p style={{ margin: 0, fontSize: "16px", fontWeight: "600", color: "#333" }}>
                        {item?.name}
                      </p>
                      <p style={{ margin: 0, fontSize: "14px", color: "#666" }}>
                        {item?.city}
                      </p>
                    </div>
                    <button
                      onClick={() => handleUpdateClinicalProfile(item?.id)}
                      className="border-0"
                      style={{
                        padding: "10px 12px",
                        backgroundColor: "#0C1C44",
                        color: "#fff",
                        fontSize: "14px",
                        fontWeight: "500",
                        borderRadius: "6px",
                        cursor: "pointer",
                        transition: "background 0.3s",
                        width: "100px",
                      }}
                      onMouseEnter={(e) => (e.currentTarget.style.background = "#0A1530")}
                      onMouseLeave={(e) => (e.currentTarget.style.background = "#0C1C44")}
                    >
                      Join
                    </button>
                  </div>
                </div>
              ))}
          </div>

          {/* Divider */}
          <hr className="mt-5 mb-4" />
          {yourClinic?.name && <Box sx={{}}>
            <h6>Your Clinic's Details</h6>

            {/* Clinic Image Upload Section */}
            <div className="Content--Section d-flex align-items-center gap-3">
              <div className="position-relative">
                {yourClinic?.image_url ? (
                  <img src={yourClinic?.image_url} width={100}
                    height={100}
                    alt={yourClinic.name}
                    loading="lazy"
                    style={{
                      borderRadius: "12px",
                      objectFit: "cover",
                      backgroundColor: "rgba(0, 0, 0, 0.07)",
                      flexShrink: 0,
                    }} />
                ) : (
                  <Skeleton sx={{ width: "120px", height: "120px", borderRadius: "20px" }} variant="rectangular" />
                )}
              </div>

            </div>

            {/* Clinic Form */}
            <Grid container spacing={2} className="mt-3">
              <Grid item xs={12} sm={4}>
                <TextField label="Clinic Name" fullWidth name="name" disabled value={yourClinic.name} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField label="Address Line 1" fullWidth name="address_line_1" disabled value={yourClinic.address_line_1} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField label="Address Line 2" fullWidth name="address_line_2" disabled value={yourClinic.address_line_2} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField label="City" fullWidth name="city" disabled value={yourClinic.city} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth >
                  <InputLabel>Country</InputLabel>
                  <Select label="Country" name="country_id" disabled value={yourClinic.country_id} >
                    {countryList.map((country) => (
                      <MenuItem key={country.id} value={country.id}>
                        {country.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth >
                  <InputLabel>State</InputLabel>
                  <Select label="State" name="state_id" disabled value={yourClinic.state_id} >
                    {yourClinicState.map((state) => (
                      <MenuItem key={state.id} value={state.id}>
                        {state.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField label="Post Code" disabled fullWidth name="postcode" value={yourClinic.postcode} />
              </Grid>
            </Grid>


          </Box>}
          <Modal open={open} onClose={() => setOpen(false)} className="create-clinic-modal" aria-labelledby="create-clinic-modal">
            <Box sx={{ width: 600, bgcolor: "white", p: 4, m: "auto", mt: 5, borderRadius: "10px", boxShadow: 24 }}>
              <h4>Add Clinic Details</h4>

              {/* Clinic Image Upload Section */}
              <div className="Content--Section d-flex align-items-center gap-3">
                <div className="position-relative">
                  {clinicDetails?.image_url && !clinicUploading ? (
                    <img src={clinicDetails?.image_url} width={120} height={120} alt="Clinic" style={{ borderRadius: "20px", objectFit: "cover" }} />
                  ) : (
                    <Skeleton sx={{ width: "120px", height: "120px", borderRadius: "20px" }} variant="rectangular" />
                  )}
                </div>
                <div className="d-flex flex-column gap-3">
                  <Button htmlFor="change-clinic-image" component="label" sx={{ backgroundColor: "#F5F5F5", color: "#414141", borderRadius: "5px", padding: "10px 20px", width: "200px", textTransform: "capitalize" }}>
                    <input id="change-clinic-image" onChange={(e) => handleChangePic(e, "clinic")} type="file" accept="image/*" style={{ display: "none" }} />
                    <ImageOutlinedIcon />
                    Add Logo
                  </Button>
                  <Button onClick={handleDeleteClinicPic} sx={{ backgroundColor: "#D2293D1A", color: "#D2293D", borderRadius: "5px", padding: "10px 20px", width: "200px", marginTop: "10px", textTransform: "capitalize" }}>
                    <DeleteOutlineOutlinedIcon />
                    Delete Logo
                  </Button>
                </div>
              </div>

              {/* Clinic Form */}
              <Grid container spacing={2} className="mt-3">
                <Grid item xs={12} sm={6}>
                  <TextField label="Clinic Name" fullWidth name="name" required value={clinicDetails.name} onChange={handleClinicInputChange} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField label="Address Line 1" fullWidth name="address_line_1" value={clinicDetails.address_line_1} onChange={handleClinicInputChange} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField label="Address Line 2" fullWidth name="address_line_2" value={clinicDetails.address_line_2} onChange={handleClinicInputChange} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField label="City" fullWidth name="city" value={clinicDetails.city} onChange={handleClinicInputChange} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth >
                    <InputLabel>Country</InputLabel>
                    <Select label="Country" name="country_id" value={clinicDetails.country_id} onChange={handleClinicInputChange}>
                      {countryList.map((country) => (
                        <MenuItem key={country.id} value={country.id}>
                          {country.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth >
                    <InputLabel>State</InputLabel>
                    <Select label="State" name="state_id" value={clinicDetails.state_id} onChange={handleClinicInputChange}>
                      {clinicstateList.map((state) => (
                        <MenuItem key={state?.id} value={state?.id}>
                          {state?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField label="Post Code" fullWidth name="postcode" value={clinicDetails.postcode} onChange={handleClinicInputChange} />
                </Grid>
              </Grid>

              {/* Action Buttons */}
              <div className="mt-4 d-flex justify-content-end gap-2">

                <button onClick={() => setOpen(false)} style={{
                  padding: "10px 12px",
                  color: "#0C1C44",
                  backgroundColor: "#fff",
                  fontSize: "14px",
                  fontWeight: "500",
                  borderRadius: "6px",
                  cursor: "pointer",
                  transition: "background 0.3s",
                  border: "1px solid #0C1C44",

                }} disabled={isSubmitting}>
                  {"Cancel"}
                </button>
                <button onClick={handleSubmit} style={{
                  padding: "10px 12px",
                  backgroundColor: "#0C1C44",
                  color: "#fff",
                  fontSize: "14px",
                  fontWeight: "500",
                  borderRadius: "6px",
                  cursor: "pointer",
                  transition: "background 0.3s",
                  border: "none",
                }} disabled={isSubmitting}>
                  {isSubmitting ? <CircularProgress size={24} /> : "Finish"}
                </button>
              </div>
            </Box>
          </Modal>
        </Box>}

    </>
  );
};




export default EditClinicalProfile;
