import {
  Close,
  CloudUploadOutlined,
  ContentCopy,
  Delete,
  Edit,
} from "@mui/icons-material";
import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import AudioVisualizer from "../lib/AudioVisualizer";
import { useSnackbar } from "notistack";
import FileUploaderModal from "../lib/FileUploaderModal";
import {
  AppendToContext,
  InsertRagContext,
  onboardingTemplate,
} from "../ClinicalNotesAPI/ClinicalNoteAPIManager";
import {
  acceptTNC,
  getDisclaimer,
  getTNC,
  sendNotification,
} from "../../../core/_request";
import DisclaimerModal from "../../modals/DisclaimerModal";
import AcceptCCOPDisclaimer from "../../modals/AcceptCCOPDisclaimer";
import Cookies from "js-cookie";
import { getSubDomain, isAusDomain } from "../lib/utils";
import dayjs from "dayjs";
import NewAudioVisualizer from "../lib/NewAudioVisualizer";
function NewTranscriptionDialog({ open, handleClose, onSuccess, data }) {
  const [text, setText] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState("editor");
  const [loading, setLoading] = useState(false);
  const [disclaimercontent, setDisclaimercontent] = useState("");
  const [tncDialog, setTncDialog] = useState(false);
  const [disclaimer, setDisclaimer] = useState(false);
  const fileRef = useRef(null);
  const copyContent = () => {
    navigator.clipboard.writeText(text);
    enqueueSnackbar("Text copied!", {
      variant: "success",
    });
  };

  let tabTemplates = [];
  useEffect(() => {
    HandleDisclaimerFromAPI();
  }, []);
  const handleSendData = () => {
    setLoading(true);

    InsertRagContext({
      transcript: text,
      session_id: `${data?._id}`,
      appointment_id: `${data?._id}`,
      user_id: `${data?.client?.user_id}`,
      expert_id: `${data?.expert?.user_id}`,
      appointment_date: `${data?.session_date}`,
      booking_date: `${data?.session_date}`,
    }).then((res) => {
      if (res?.error) {
        enqueueSnackbar(res?.message, { variant: "info" });
        setLoading(false);
        return;
      }
      uploadRawText(text);

      setTimeout(() => {
        const dropdownData = [
          "academic_functioning",
          "employment_status",
          "marital_functioning",
          "marital_status",
          "social_functioning",
        ];

        onboardingTemplate(data?.expert?.user_id).then((resp) => {
          if (resp.ResponseCode === 200) {
            tabTemplates = resp?.template;
            const template = tabTemplates?.map((it) => ({
              name: dropdownData.includes(it?.name) ? "dropdown" : it?.name,
            }));
            checkTnc();
            AppendToContext({
              transcript: text,
              session_id: `${data?._id}`,
              appointment_id: `${data?._id}`,
              user_id: `${data?.client?.user_id}`,
              expert_id: `${data?.expert?.user_id}`,
              appointment_date: `${data?.session_date}`,
              booking_date: `${data?.session_date}`,
              ordered_fields_json: template,
            }).then((res) => {
              if (res?.error) {
                setLoading(false);
                enqueueSnackbar(res?.message, { variant: "info" });
                return;
              }
              enqueueSnackbar(
                "Your transcript has been submitted successfully",
                {
                  variant: "success",
                }
              );
              setLoading(false);
              setText("");
            });
          } else {
            setLoading(false);
            enqueueSnackbar(resp?.ResponseMessage, { variant: "error" });
            return;
          }
        });
      }, 10000);
    });
  };
  const HandleDisclaimerFromAPI = async () => {
    const response = await getDisclaimer();
    const parser = new DOMParser();
    const doc = parser.parseFromString(response, "text/html");
    const contentDiv = doc.querySelector(".theme-doc-markdown.markdown");
    const encryptedEmails = contentDiv.querySelectorAll(".__cf_email__");
    encryptedEmails.forEach((element) => {
      element.innerHTML = `<a  href="mailto:${process.env.REACT_APP_CLIENT_EMAIL}">${process.env.REACT_APP_CLIENT_EMAIL}</a>`;
    });
    if (contentDiv) {
      setDisclaimercontent(contentDiv.innerHTML);
    } else {
      const fallbackMessage = `
                        Disclaimer. 
                        <a href="https://legal${getSubDomain()}/disclaimer-ccop" target="_blank">Read more</a>`;
      document.getElementById("disclaimer-content").innerText = fallbackMessage;
      setDisclaimercontent(fallbackMessage);
    }
  };
  const uploadRawText = async (text_data) => {
    const storedData = localStorage.getItem("Authorization");
    const token = storedData ? storedData?.split("Bearer ")[1] : null;
    try {
      const response = await fetch(
        `${process.env.REACT_APP_EXPERT_BASE_URL}clinicalnotes/update-raw-data`,
        {
          method: "POST",
          headers: {
            accept: "application/json",
            token: token,
            "Content-Type": "application/json",
            authenticationToken: Cookies.get("authDataJwt"),
          },
          body: JSON.stringify({
            id: `${data?._id}`,
            user_id: `${data?.client?.user_id}`,
            expert_id: `${data?.expert?.user_id}`,
            state: "In Progress",
            raw_data: text_data,
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      console.error("Fetching assessment list failed:", error);
      throw error;
    }
  };

  const acceptTnc = async () => {
    const tnc = await acceptTNC(data?.expert?.user_id);
    if (tnc?.success) {
      fetchNotification();
      setTncDialog(false);
      checkTnc();
    } else {
      enqueueSnackbar("Error accepting T&C.", { variant: "error" });
    }
  };
  const checkTnc = async () => {
    const tnc = await getTNC(data?.expert?.user_id);
    if (tnc?.success) {
      if (tnc?.status) {
        // If user accepted Disclaimer check caution
        checkDisclaimer();
      } else {
        // If user not accepted Disclaimer show Disclaimer dialog
        window.localStorage.removeItem("disclaimer");
        setTncDialog(true);
      }
      // fetchNotification()
    } else {
      enqueueSnackbar("Error fetching disclaimer status.", {
        variant: "error",
      });
    }
  };

  const fetchNotification = async () => {
    try {
      const notificationBody = {
        event: isAusDomain() ? "ccop_register_australia" : "ccop_accept_terms",
        event_metadata: {
          timestamp: dayjs().unix(),
        },
        user_id: `${data?.expert?.user_id}`,
      };
      sendNotification(JSON.stringify(notificationBody));
    } catch (error) {}
  };
  const checkDisclaimer = () => {
    const disclaimerStatus = JSON.parse(
      window.localStorage.getItem("disclaimer")
    )?.status;
    if (!disclaimerStatus) {
      // If user not accepted caution show caution dialog
      setDisclaimer(true);
    } else {
      setLoading(false);
      onSuccess();
    }
  };
  const inputRef = useRef(null);
  const focus = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };
  useEffect(() => {
    focus();
  }, [state]);

  const scrollChatToBottom = () => {
    console.log("scrolling");

    const chatElement = fileRef.current;
    if (chatElement) {
      // Use setTimeout to allow DOM updates to complete before scrolling
      setTimeout(() => {
        chatElement.scrollTop = chatElement.scrollHeight;
      }, 0);
    }
  };

  const scrollChatToTop = () => {
    console.log("scrolling to top");

    const chatElement = fileRef.current;
    if (chatElement) {
      // Use setTimeout to allow DOM updates to complete before scrolling
      setTimeout(() => {
        chatElement.scrollTop = 0;
      }, 0);
    }
  };

  return (
    <Dialog className="notetaker-new-dialog " open={open} onClose={handleClose}>
      <DialogTitle className="d-flex flex-column d-sm-none gap-2 p-2 p-lg-4 ">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <h5 className="m-0 ">Voice and Document Transcriptions </h5>
          </div>
          <IconButton onClick={onSuccess}>
            <Close />
          </IconButton>
        </div>

        <div className="d-flex flex-wrap align-items-center gap-2 gap-md-3">
          <Button
            sx={{
              borderRadius: "10px", // Rounded but not too much
              bgcolor: "var(--primary-color-opacity50)",
              color: "var(--primary-color)",
              fontSize: "14px", // 14px as in the default theme
              textTransform: "capitalize",
              "&:hover": {
                background: "var(--primary-color-opacity50)",
              },
            }}
            className="d-flex align-items-center gap-2"
            id="notetaker_upload_document_for_transcription"
            onClick={() => {
              setState(state === "editor" ? "fileupload" : "editor");
            }}
          >
            {state === "editor" ? <CloudUploadOutlined /> : <Edit />}
            {state === "editor" ? "Upload" : "Back to Editor"}
          </Button>
          <Button
            onClick={handleSendData}
            disabled={!(text?.trim() !== "" && data?._id)}
            sx={{
              borderRadius: "10px", // Rounded but not too much
              bgcolor: "var(--primary-color)",
              color: "white",
              fontSize: "14px", // 14px as in the default theme
              textTransform: "capitalize",
              "&:hover": {
                background: "var(--primary-color-darken)",
              },
              "&:disabled": {
                background: "var(--primary-color-opacity50)",
                color: "white",
              },
            }}
            className="d-flex align-items-center gap-2"
            id="notetaker_send_transcription"
          >
            <img
              width={24}
              src={"/assets/img/clinical_notes_assets/Frame-submit.png"}
              alt="submit"
            />
            Send Transcription
          </Button>
        </div>
      </DialogTitle>
      <DialogTitle className="d-sm-flex d-none gap-2 p-2 p-lg-4 pb-0 pb-lg-0 align-items-center  justify-content-between">
        <h5 className="m-0">Voice and Document Transcriptions</h5>
        <div
          style={{ minWidth: "455px" }}
          className="d-flex justify-content-end align-items-center  gap-2 gap-md-3"
        >
          <Button
            sx={{
              bgcolor: "var(--primary-color-opacity50)",
              color: "var(--primary-color)",
              textTransform: "capitalize",
              "&:hover": {
                background: "var(--primary-color-opacity50)",
              },
            }}
            className="PrimaryCTA---ALT"
            id="notetaker_upload_document_for_transcription"
            onClick={() => {
              setState(state === "editor" ? "fileupload" : "editor");
            }}
          >
            {state === "editor" ? <CloudUploadOutlined /> : <Edit />}
            {state === "editor" ? "Upload" : "Back to Editor"}
          </Button>
          <Button
            onClick={handleSendData}
            disabled={!(text?.trim() !== "" && data?._id)}
            className="PrimaryCTA"
            id="notetaker_send_transcription"
          >
            <img
              width={24}
              src={"/assets/img/clinical_notes_assets/Frame-submit.png"}
              alt="submit"
            />
            Send Transcription
          </Button>
          <div>
            <IconButton
              id="notetaker_close_button"
              className="  "
              onClick={onSuccess}
            >
              <Close />
            </IconButton>
          </div>
        </div>{" "}
      </DialogTitle>

      <DialogContent className="p-0" ref={fileRef}>
        {state === "editor" && (
          <div className="mt-3">
            <div className="p-2 p-lg-4 pb-0 pb-lg-0">
              <div
                style={{
                  width: "100%",
                  boxShadow: "0px 20px 50px 0px var(--primary-color-opacity50)",
                }}
                className="rounded-top"
              >
                <textarea
                  ref={inputRef}
                  autoFocus={true}
                  placeholder="Start Typing..."
                  id="speakArea"
                  label="Speak Now"
                  style={{
                    width: "100%",
                    resize: "none",
                    border: "none",
                    maxHeight: "350px",
                  }}
                  className="SpeechTextArea p-2"
                  rows={20}
                  cols={10}
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                />
                {text?.trim() !== "" && (
                  <div className="d-flex align-items-center">
                    <IconButton
                      id="notetaker_copy_text_button"
                      onClick={copyContent}
                    >
                      <ContentCopy />
                    </IconButton>
                    <IconButton
                      id="notetaker_remove_text_button"
                      onClick={() => setText("")}
                    >
                      <Delete />
                    </IconButton>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        <div className="px-2 px-lg-4">
          {/* <AudioVisualizer
            transcription={(data) => {
              
              setText((prev) => prev + " " + data);
              // scrollChatToTop();
            }}
            state={state}
            scrollOnChange={scrollChatToBottom}
          /> */}
          <NewAudioVisualizer
            transcription={(data) => {
              setText((prev) => prev + " " + data);
              // scrollChatToTop();
            }}
            state={state}
          />
        </div>

        <FileUploaderModal
          data={data}
          transcription={(data) => {
            setText(text + " " + data);
            setState("editor");
            scrollChatToTop();
          }}
          state={state}
          scrollOnChange={scrollChatToBottom}
        />
        <DisclaimerModal
          disclaimer={disclaimer}
          close={() => setDisclaimer(true)}
          handleAccept={() => {
            window.localStorage.setItem(
              "disclaimer",
              JSON.stringify({ status: true })
            );

            setDisclaimer(false);
            setLoading(false);
            onSuccess();
          }}
        />
        <AcceptCCOPDisclaimer
          open={tncDialog}
          close={() => setTncDialog(true)}
          disclaimercontent={disclaimercontent}
          handleAccept={() => {
            acceptTnc();
          }}
        />
        <Backdrop
          sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
          open={loading}
        >
          <img
            alt=""
            src="/assets/img/icons/aisparkle.png"
            width={50}
            className="animate-pulse"
          />
        </Backdrop>
      </DialogContent>
    </Dialog>
  );
}

export default NewTranscriptionDialog;
