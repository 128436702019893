import React from "react";
import { Button, FormControl, MenuItem, Select } from "@mui/material";
import { BorderColorOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

function LetterTemplateForm({
  selectedTemplate,
  handleTemplateChange,
  template,
  userDetails,
  clinicDetails,
  clinic_image,
}) {
  const navigate = useNavigate();
  return (
    <div
      className="w-full p-2 p-md-3 py-2 py-md-2"
      style={{ background: "#fff" }}
    >
      <div>
        {/* Select Letter Type */}
        <div
          style={{ background: "#F2F4F7", border: "1px solid #D8D8D8" }}
          className="rounded-3 p-4 mt-3 d-flex align-items-center flex-wrap justify-content-between"
        >
          <div>
            <h5 style={{ color: "#0C1C44" }}>Select Letter Type</h5>
            <p className="text-muted">
              Choose the kind of letter you want to send, and we will fill in a
              template for you.
            </p>
          </div>
          <FormControl>
            <Select
              value={selectedTemplate}
              onChange={handleTemplateChange}
              displayEmpty
              size="small"
              sx={{
                maxWidth: "300px !important",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#D8D8D8",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#0C1C44",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#0C1C44 !important",
                },
              }}
            >
              <MenuItem value="" disabled>
                <p style={{ color: "#0C1C44", margin: 0 }}>
                  Select letter type
                </p>
              </MenuItem>
              {template
                ?.filter((item) => item?.is_active)
                ?.map((item, idx) => (
                  <MenuItem key={idx} value={item?.template_name}>
                    {item?.template_name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>

        {/* Review Personal Details */}
        <div
          style={{
            background: "#fff",
            border: "1px solid #D8D8D8",
            borderRadius: "10px 10px 0px 0px",
          }}
          className="p-4 mt-3 d-flex align-items-center flex-wrap justify-content-between"
        >
          <div>
            <h5>Review Your Personal Details</h5>
            <p className="text-muted">
              These details from your profile will be reflected in your letter.
              If you want to modify or add to them, click on the 'Edit' icon to
              go to your Profile.
            </p>
          </div>
          <Button
            sx={{
              backgroundColor: "#F2F4F7",
              color: "#0C1C44",
              borderColor: "#0C1C44",
              "&:hover": {
                backgroundColor: "#F2F4F7",
                color: "#0C1C44",
                borderColor: "#0C1C44",
              },
              "&:focus": {
                backgroundColor: "#F2F4F7",
                color: "#0C1C44",
                borderColor: "#0C1C44",
              },
            }}
            className="p-3 rounded-4"
            variant="outlined"
            onClick={() => navigate("/clinical/settings")}
          >
            <BorderColorOutlined />
          </Button>
        </div>

        {/* User & Clinic Details */}
        <div
          style={{
            background: "#fff",
            borderWidth: "0px 1px 1px 1px",
            borderStyle: "solid",
            borderColor: "#D8D8D8",
            borderRadius: "0px 0px 10px 10px",
          }}
          className="p-4"
        >
          {/* User Details */}
          <div>
            <h6 className="text-muted">Your Details</h6>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                gap: "16px",
              }}
              className="mt-3"
            >
              {userDetails?.map((detail, index) => (
                <div key={index}>
                  <h6>{detail?.label}</h6>
                  <p
                    className="p-2 rounded-3 font-w500"
                    style={{
                      background: "#F2F4F7",
                      color: "#0C1C44",
                      minHeight: "40px",
                    }}
                  >
                    {detail?.value}
                  </p>
                </div>
              ))}
            </div>
          </div>

          {/* Clinic Details */}
          <div className="mt-4">
            <h6 className="text-muted">Your Clinic's Details</h6>
            <div className="d-flex align-items-center flex-wrap gap-4 mt-3">
              <h6>Clinic's Logo</h6>
              <img
                src={clinic_image}
                width={50}
                height={50}
                alt=""
                loading="lazy"
                style={{
                  objectFit: "cover",
                  backgroundColor: "rgba(0,0,0,0.07)",
                }}
              />
            </div>

            {/* Clinic Info Rows */}
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
                gap: "16px",
              }}
              className="mt-3"
            >
              {clinicDetails.slice(0, 3).map((detail, index) => (
                <div key={index}>
                  <h6>{detail.label}</h6>
                  <p
                    className="p-2 rounded-3 font-w500"
                    style={{
                      background: "#F2F4F7",
                      color: "#0C1C44",
                      minHeight: "40px",
                    }}
                  >
                    {detail.value}
                  </p>
                </div>
              ))}
            </div>

            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
                gap: "16px",
                marginTop: "16px",
              }}
            >
              {clinicDetails.slice(3).map((detail, index) => (
                <div key={index}>
                  <h6>{detail.label}</h6>
                  <p
                    className="p-2 rounded-3 font-w500"
                    style={{
                      background: "#F2F4F7",
                      color: "#0C1C44",
                      minHeight: "40px",
                    }}
                  >
                    {detail.value}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LetterTemplateForm;
