import {
  BorderColorOutlined,
  BorderColorRounded,
  Close,
  Download,
  Send,
  SendOutlined,
} from "@mui/icons-material";
import {
  Button,
  FormControl,
  IconButton,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import LetterTemplateForm from "./LetterTemplateForm";
import LetterTemplateDetails from "./LetterTemplateDetails";
import LetterTemplateDetailsFields from "./LetterTemplateDetailsFields";
import LetterTemplateBody from "./LetterTemplateBody";
import { useSnackbar } from "notistack";

function LetterTemplates({ close, userData }) {
  const [template, setTemplate] = useState([]); // Store templates
  const [selectedTemplate, setSelectedTemplate] = useState(""); // Selected template
  const [selectedTemplateData, setSelectedTemplateData] = useState({});
  const [currentStep, setCurrentStep] = useState(1); // Step state
  const [templateDetails, setTemplateDetails] = useState({});
  const [selectedFields, setSelectedFields] = useState({}); // Store selected fields
  const [clinicalNoteFields, setClinicalNoteFields] = useState("");
  const [download, setDownload] = useState(false);
  const [allDetails, setAllDetails] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const headers = {
    "X-API-Key": process.env.REACT_APP_EXPERT_API_KEY,
    "Content-Type": "application/json",
  };
  const userDetails = [
    { label: "Name", value: allDetails?.name || "" },
    { label: "Email", value: allDetails?.email || "" },
    { label: "Prescriber Number", value: allDetails?.prescriber_number || "" },
    { label: "Qualifications", value: allDetails?.qualification || "" },
    { label: "Practitioner Type", value: allDetails?.register_as || "" },
    { label: "AHPRA Number", value: allDetails?.ahpra_number || "" },
  ];
  const clinicDetails = [
    { label: "Clinic’s Name", value: allDetails?.clinic?.name || "" },
    { label: "Address 1", value: allDetails?.clinic?.address_line_1 || "" },
    { label: "Address 2", value: allDetails?.clinic?.address_line_2 || "" },
    { label: "City/Suburb", value: allDetails?.clinic?.city || "" },
    { label: "State", value: allDetails?.clinic?.state || "" },
    { label: "Postcode", value: allDetails?.clinic?.postcode || "" },
    { label: "Country", value: allDetails?.clinic?.country || "" },
  ];
  useEffect(() => {
    if (!userData?.headerData?.expert_id) {
      return;
    }
    fetchTemplate();
    fetchUserDetails();
    setTemplateDetails((prev) => ({
      ...prev,
      patient_name: allDetails?.patientName || userData?.client?.name || "",
      patient_dob: allDetails?.patientDob || userData?.client?.dob || "",
    }));
  }, [userData?.headerData?.expert_id]);
  const mappedDetails = {
    recipient_name: allDetails?.recipientName || "",
    patient_name: allDetails?.patientName || "",
    patient_dob: allDetails?.patientDob || "",
    clinical_note_fields: allDetails?.clinicalNoteFields || "",
    user_name: allDetails?.name || "",
    user_practitioner_type: allDetails?.register_as || "",
    clinic_name: allDetails?.clinic?.name || "",
    clinic_address_1: allDetails?.clinic?.address_line_1 || "",
    clinic_address_2: allDetails?.clinic?.address_line_2 || "",
    clinic_city: allDetails?.clinic?.city || "",
    clinic_state: allDetails?.clinic?.state || "",
    clinic_country: allDetails?.clinic?.country || "",
    clinic_postcode: allDetails?.clinic?.postcode || "",
    user_email: allDetails?.email || "",
    user_practitioner_number: allDetails?.prescriber_number || "",
    user_ahpra_number: allDetails?.ahpra_number || "",
    cannabis_start_date: allDetails?.cannabisStartDate || "",
    case_reference_number: allDetails?.caseReferenceNumber || "",
    professional_medical_opinion: allDetails?.professionalMedicalOpinion || "",
    clinic_image: allDetails?.clinic?.image_url || "",
  };
  const fetchTemplate = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_INTEGRATION_URL}session/get_letter_templates`
      );
      const data = await response.json();
      if (data.status === "success") {
        setTemplate(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchUserDetails = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_US_BASE_URL}new-uwc/uwc_expert/copilot/get-professional-details?professional_id=${userData?.headerData?.expert_id}`,
        { method: "GET", headers }
      );
      const data = await response.json();
      if (data.ResponseMessage === "success") {
        setAllDetails(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleTemplateChange = (event) => {
    setSelectedTemplate(event.target.value);
    setSelectedTemplateData(
      template?.find((item) => item?.template_name === event.target.value)
    );
    setTemplateDetails({
      patient_name: allDetails?.patientName || userData?.client?.name || "",
      patient_dob: allDetails?.patientDob || userData?.client?.dob || "",
    });
  };

  const handleNextStep = () => {
    // If we are at step 2 and clinical notes are not shown, move directly to step 4
    if (!selectedTemplate) {
      enqueueSnackbar("Please select a letter type.", { variant: "error" });
      return;
    }
    if (currentStep === 2 && !selectedTemplateData?.show_clinical_note_fields) {
      setCurrentStep(4);
    } else {
      setCurrentStep((prevStep) => prevStep + 1);
    }
  };

  useEffect(() => {
    if (selectedTemplate) {
      setSelectedFields({});
      setClinicalNoteFields("");
      // Clear selectedTemplateData when going to Step 2
    }
  }, [selectedTemplate]);

  const handleBackStep = () => {
    // Check if we're going to step 2, and reset selectedTemplateData if so

    if (currentStep === 4 && !selectedTemplateData?.show_clinical_note_fields) {
      setCurrentStep(2); // Go back to Step 2 if clinical notes are skipped
    } else {
      setCurrentStep((prevStep) => prevStep - 1); // Move to the previous step
    }
  };

  const handleTemplateDetailsChange = (updatedData) => {
    setTemplateDetails(updatedData);
  };

  return (
    <div>
      <p className="m-0 p-2 p-md-3 border-bottom d-flex justify-content-between align-items-center">
        <h4 className="m-0 text-muted">Letter Templates</h4>

        <div className="d-flex align-items-center gap-2">
          {currentStep === 4 && (
            <div className="d-flex align-items-center gap-2">
              {/* <Button
                sx={{
                  backgroundColor: "#F2F4F7",
                  color: "#0C1C44",
                  borderColor: "#0C1C44",
                  "&:hover": {
                    backgroundColor: "#F2F4F7",
                    color: "#0C1C44",
                    borderColor: "#0C1C44",
                  },
                  "&:focus": {
                    backgroundColor: "#F2F4F7",
                    color: "#0C1C44",
                    borderColor: "#0C1C44",
                  },
                }}
                className="p-2 rounded-3"
                variant="outlined"
                endIcon={<Send sx={{ color: "#0C1C44" }} />}
              >
                Send to Patient
              </Button> */}
              <Button
                sx={{
                  backgroundColor: "#F2F4F7",
                  color: "#0C1C44",
                  borderColor: "#0C1C44",
                  "&:hover": {
                    backgroundColor: "#F2F4F7",
                    color: "#0C1C44",
                    borderColor: "#0C1C44",
                  },
                  "&:focus": {
                    backgroundColor: "#F2F4F7",
                    color: "#0C1C44",
                    borderColor: "#0C1C44",
                  },
                }}
                className="p-2 rounded-3"
                variant="outlined"
                onClick={() => setDownload(true)}
                endIcon={<Download sx={{ color: "#0C1C44" }} />}
              >
                Download PDF
              </Button>
            </div>
          )}

          <IconButton onClick={close}>
            <Close />
          </IconButton>
        </div>
      </p>
      <div
        className="w-full p-2 p-md-3 py-2 py-md-2"
        style={{ background: "#fff", borderBottom: "1px solid #D8D8D8" }}
      >
        <div>
          <img src={process.env.REACT_APP_CLIENT_IMAGE} alt="" height={50} />
        </div>
      </div>

      {/* Step 1: Template Selection */}
      {currentStep === 1 && (
        <LetterTemplateForm
          clinic_image={allDetails?.clinic?.image_url || ""}
          selectedTemplate={selectedTemplate}
          handleTemplateChange={handleTemplateChange}
          template={template}
          userDetails={userDetails}
          clinicDetails={clinicDetails}
        />
      )}

      {/* Step 2: Letter Template Details */}
      {currentStep === 2 && (
        <LetterTemplateDetails
          inputFields={selectedTemplateData?.input_fields}
          formData={templateDetails} // Controlled state
          onFormChange={handleTemplateDetailsChange} // Handler function
        />
      )}

      {/* Step 3: Clinical Note Fields Selection */}
      {currentStep === 3 && selectedTemplateData?.show_clinical_note_fields && (
        <LetterTemplateDetailsFields
          clinicalNoteFields={setClinicalNoteFields} // ✅ Pass state as prop
          selectedFields={selectedFields} // ✅ Pass state as prop
          setSelectedFields={setSelectedFields} // ✅ Pass state updater function as prop
        />
      )}

      {/* Step 4: Letter Body */}
      {currentStep === 4 && (
        <LetterTemplateBody
          download={download}
          setDownload={setDownload}
          template={selectedTemplateData}
          userDetails={{
            ...mappedDetails,
            ...templateDetails,
            clinical_note_fields: clinicalNoteFields || "",
          }}
        />
      )}

      {/* Navigation Buttons */}
      <div className="clinicalnotes-submit-save d-flex justify-content-end gap-3 w-full p-2 p-md-3 pb-md-5 pb-4">
        {currentStep > 1 && (
          <Button onClick={handleBackStep} className="save">
            Back
          </Button>
        )}

        {currentStep < 4 && (
          <Button onClick={handleNextStep} className="PrimaryCTA">
            Next
          </Button>
        )}
      </div>
    </div>
  );
}

export default LetterTemplates;
