import React, { useEffect } from "react";

const capitalizeFirstLetter = (str) =>
  str.split(" ").map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");

function LetterTemplateDetails({ inputFields, formData, onFormChange }) {
  // Update form data when an input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    onFormChange({ ...formData, [name]: value });
  };

  useEffect(() => {
    if (inputFields && inputFields.length > 0 && !Object.keys(formData).length) {
      const initialData = inputFields.reduce((acc, field) => {
        acc[field.name] = formData[field.name] || ""; // Prefill from formData
        return acc;
      }, {});
      onFormChange(initialData);
    }
  }, [inputFields]);

  if (!inputFields || inputFields.length === 0) {
    return <div>Loading or No Input Fields Available</div>;
  }

  return (
    <div className="w-full p-2 p-md-3 py-2 py-md-2" style={{ background: "#fff" }}>
      <div
        style={{ background: "#fff", border: "1px solid #D8D8D8", borderRadius: "10px 10px 0px 0px" }}
        className="p-4 mt-3 d-flex align-items-center flex-wrap justify-content-between"
      >
        <div>
          <h5>Enter Letter Details</h5>
          <p className="text-muted">We need the following details to prepare your template.</p>
        </div>
      </div>

      <div
        style={{
          background: "#fff",
          borderWidth: "0px 1px 1px 1px",
          borderStyle: "solid",
          borderColor: "#D8D8D8",
          borderRadius: "0px 0px 10px 10px",
        }}
        className="p-4"
      >
        {/* <h6 className="text-muted">Other Details</h6> */}
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
            gap: "16px",
          }}
          className="mt-3"
        >
          {inputFields.map((field, index) => (
            <div key={index} style={{ maxWidth: "500px", width: "100%" }}>
              <h6>{field.label}</h6>
              <input
                type={field.input_type === "string" ? "text" : field.input_type}
                name={field.name}
                placeholder={capitalizeFirstLetter(field.placeholder)}
                value={formData[field.name] || ""}
                onChange={handleInputChange}
                className="p-2 rounded-3 font-w500"
                style={{
                  background: "#F2F4F7",
                  color: "#0C1C44",
                  width: "100%",
                  border: "1px solid #D8D8D8",
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default LetterTemplateDetails;
